
import { defineComponent } from "vue";

import TransportMotorCatalogue from "@/components/catalogues/TransportCatalogue/TransportMotorCatalogue.vue";

export default defineComponent({
  components: { TransportMotorCatalogue },
  props: {},
  methods: {},
  data() {
    return {};
  },
});
